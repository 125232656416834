import Post from './Post';
import { useEffect, useState } from 'react';


const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [sorting, setSorting] = useState("Recent");

  // sorts the array based on timestamp and value
  const compareTimestamp = (x, y) => {
    if (sorting === "Recent") {
      return y.timestamp - x.timestamp;
    }
    else if (sorting === "Oldest") {
      return x.timestamp - y.timestamp;
    }
  }

  // fetching all the posts from the worker API
  useEffect(() => {
    const fetchPosts = async () => {
      const resp = await fetch(`https://my-worker.nouman.workers.dev/posts/`);
      const newData = await resp.json();
      // const newData = [{ "title": "My First Post", "username": "coolguy123", "content": "Hey Y'all!" }, { "title": "Story About my Dogs", "username": "kn0thing", "content": "So the other day I was in the yard, and..." }]
      console.log("Succesfully fetched posts");
      newData.sort((x, y) => y.timestamp - x.timestamp);
      setPosts(newData);
    };
    fetchPosts();
  }, []);

  return (
    <div className="feed">
      <h2 className="text-center mt-5">Blog posts</h2>
      <div className="float-end pr-5" >
        Sort:{" "}
        {["Recent", "Oldest"].map(s => (
          <label className="p-1" key={s}>
            <input
              type="radio"
              name="sort"
              value={s}
              checked={sorting === s}
              onChange={e => setSorting(e.currentTarget.value)}
            />
            {" " + s}
          </label>
        ))}
      </div>
      {posts.sort(compareTimestamp).map((post, index) => (
        <Post key={index} data={post} />
      ))}
    </div>
  );
}

export default Posts;
