import React from 'react';

const Post = ({ data }) => {
  // console.log(data);
  const date = new Date(data.timestamp);

  let content = (type) => {
    if (type === "image") {
      return <img src={data.content} className="img-fluid"/>
    }
    return <p className="mt-2">{data.content}</p>;
  }
  
  return (
    <div className="card">
      <h4 className="mb-0">{data.title}</h4>
      <div>
        <small>Posted by: <i className="fas fa-user"></i>  {data.username}</small>
        <small className="float-end">{date.getMonth()}/{date.getDate()}/{date.getFullYear()}</small>
      </div>
      {content(data.type)}
    </div>
  );
}

export default Post;