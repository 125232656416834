import './App.css';
import CreatePost from './components/CreatePost';
import Posts from './components/Posts';


function App() {
  return (
    <div className="App">
      <header className="header">
        My cloudflare blog site
      </header>
      {/* <CreatePost /> */}
      <Posts />

      <footer className="mt-5 text-center">
        <small >
          Created by <a href="https://mnoumanabbasi.github.io/">Nouman Abbasi</a>
        </small>
      </footer>
    </div>
  );
}

export default App;
